// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
  // scss-docs-start badge-css-vars
  --#{$prefix}badge-padding-x: #{$badge-padding-x};
  --#{$prefix}badge-padding-y: #{$badge-padding-y};
  @include rfs($badge-font-size, --#{$prefix}badge-font-size);
  --#{$prefix}badge-font-weight: 400;
  --#{$prefix}badge-color: #{$badge-color};
  --#{$prefix}badge-border-radius: 0.3rem;
  // scss-docs-end badge-css-vars

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.07rem 0.2rem;
  @include font-size(var(--#{$prefix}badge-font-size));
  font-weight: var(--#{$prefix}badge-font-weight);
  line-height: 1.4;
  color: var(--#{$prefix}badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  @include border-radius(var(--#{$prefix}badge-border-radius));
  @include gradient-bg();

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}

// Quick fix for badges in buttons
.btn .badge {
  position: relative;
  top: -1px;
}
