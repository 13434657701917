@import "forms/labels";
@import "forms/form-text";
@import "forms/form-control";
@import "forms/form-select";
@import "forms/form-check";
@import "forms/form-range";
@import "forms/floating-labels";
@import "forms/input-group";
@import "forms/validation";


label{
    font-size: 1rem;
    // color: #fff;
    display: block;
    margin-bottom: 0.4rem;
}
.react-datepicker-wrapper {
    display: block !important;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        right: 0.5rem;
        top: 50%;
        width: 1.5rem;
        height: 1.5rem;
        transform: translateY(-50%);
        background: url('../assets/images/calendar.svg') no-repeat center/100% 100%;
    }    
}
.form-control,input{
    box-shadow: none;
    border-color: #8b8b8b;
    // color: #fff !important;
    background-color: unset !important;
    font-size: 0.9rem;
    height: 2.6rem;
    line-height: normal;
    &:focus{
        background-color: unset;
        box-shadow: none;
        border-color: #FFAD39;
    }
    &::placeholder{
        color: #CCCCCC;        
    }
    &:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus,&:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;        
        -webkit-text-fill-color: #000000;
        caret-color: #000000 !important;
    }
}
.dropzone{
    input{
        display: none;
    }
    .dropzone-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        border: 2px dashed #CCCCCC;
        border-radius: 0.4rem;
        background-color: $white;
        cursor: pointer;
        &:hover,&:focus{           
            border-color: $yellow;
            .file-icon{                
                color: $yellow;                
            }
            .dropzone-title{
                color: $yellow;
            }
        }
        .file-icon{
            color: #CCCCCC;
            margin-bottom: 0.6rem;
            svg{
                width: 2.5rem;
                height: 2.5rem;
            }           
        }
        .dropzone-title{
            color: #CCCCCC;
            .browse{
                color: $secondary;
            }
        }
    }
    .attachment-data-box{
        svg{
            width: 2rem;
            height: 2rem;
        }
        h6{
            font-size: 1.1rem;
            color: $primary;
            font-weight: 500;
        }
    }
    .attachment-data{        
        padding-bottom: 0.25rem;
        svg{
            width: 1.6rem;
            height: 1.6rem;
        }
        h6{
            font-size: 1rem;
            max-width: 24rem;
            white-space: nowrap;
            overflow: hidden;           
            text-overflow: ellipsis;
            line-height: 1.4;
        }
        background-color: lighten($primary, 25%);        
        border-radius: 0.3rem;
        max-width: 34%;
        .attachment-head{
            position: relative;     
            img {
                border-radius: 0.3rem;
            }       
            .file-box{                
                padding: 0.25rem;                
                padding-bottom: 0;
                display: flex;
                gap: 0.4rem;
                align-items: center;
                cursor: pointer;
            }
            .remove-box{
                color: $danger;
                display: flex;
                justify-content: center;
                align-items: center;                
                position: absolute;
                top: 0.44rem;
                right: 0.4rem;
                width: 1.6rem;
                height: 1.6rem;
                border-radius: 50%;
                background-color: lighten($danger, 42%);        
                cursor: pointer;
                svg{
                    width: 1rem;
                    height: 1rem;
                }
                &:hover {
                    color: $primary;
                }
            }   
        }  
        .progress_bar{
            margin: 0rem 0.4rem;
        }      
    }
}