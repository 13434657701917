//
// Base styles
//

.btn {
  // scss-docs-start btn-css-vars
  --#{$prefix}btn-padding-x: #{$btn-padding-x};
  --#{$prefix}btn-padding-y: #{$btn-padding-y};
  --#{$prefix}btn-font-family: #{$btn-font-family};
  @include rfs($btn-font-size, --#{$prefix}btn-font-size);
  --#{$prefix}btn-font-weight: #{$btn-font-weight};
  --#{$prefix}btn-line-height: #{$btn-line-height};
  --#{$prefix}btn-color: #{$btn-color};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-width: #{$btn-border-width};
  --#{$prefix}btn-border-color: transparent;
  --#{$prefix}btn-border-radius: 30px;
  --#{$prefix}btn-hover-border-color: transparent;
  --#{$prefix}btn-box-shadow: #{$btn-box-shadow};
  --#{$prefix}btn-disabled-opacity: #{$btn-disabled-opacity};
  --#{$prefix}btn-focus-box-shadow: 0 0 0 #{$btn-focus-width} rgba(var(--#{$prefix}btn-focus-shadow-rgb), .5);
  // scss-docs-end btn-css-vars

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: fit-content;
  padding: var(--#{$prefix}btn-padding-y) var(--#{$prefix}btn-padding-x);
  font-family: var(--#{$prefix}btn-font-family);
  @include font-size(var(--#{$prefix}btn-font-size));
  font-weight: var(--#{$prefix}btn-font-weight);
  line-height: var(--#{$prefix}btn-line-height);
  color: var(--#{$prefix}btn-color);
  text-align: center;
  text-decoration: if($link-decoration ==none, null, none);
  white-space: $btn-white-space;
  vertical-align: middle;
  cursor: if($enable-button-pointers, pointer, null);
  user-select: none;
  border: var(--#{$prefix}btn-border-width) solid var(--#{$prefix}btn-border-color);
  @include border-radius(var(--#{$prefix}btn-border-radius));
  @include gradient-bg(var(--#{$prefix}btn-bg));
  @include box-shadow(var(--#{$prefix}btn-box-shadow));
  @include transition($btn-transition);

  &:hover {
    color: var(--#{$prefix}btn-hover-color);
    text-decoration: if($link-hover-decoration ==underline, none, null);
    background-color: var(--#{$prefix}btn-hover-bg);
    border-color: var(--#{$prefix}btn-hover-border-color);
  }

  .btn-check+&:hover {
    // override for the checkbox/radio buttons
    color: var(--#{$prefix}btn-color);
    background-color: var(--#{$prefix}btn-bg);
    border-color: var(--#{$prefix}btn-border-color);
  }

  &:focus-visible {
    color: var(--#{$prefix}btn-hover-color);
    @include gradient-bg(var(--#{$prefix}btn-hover-bg));
    border-color: var(--#{$prefix}btn-hover-border-color);
    outline: 0;

    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: var(--#{$prefix}btn-box-shadow),
      var(--#{$prefix}btn-focus-box-shadow);
    }

    @else {
      box-shadow: var(--#{$prefix}btn-focus-box-shadow);
    }
  }

  .btn-check:focus-visible+& {
    border-color: var(--#{$prefix}btn-hover-border-color);
    outline: 0;

    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: var(--#{$prefix}btn-box-shadow),
      var(--#{$prefix}btn-focus-box-shadow);
    }

    @else {
      box-shadow: var(--#{$prefix}btn-focus-box-shadow);
    }
  }

  .btn-check:checked+&,
  :not(.btn-check)+&:active,
  &:first-child:active,
  &.active,
  &.show {
    color: var(--#{$prefix}btn-active-color);
    background-color: var(--#{$prefix}btn-active-bg);
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: var(--#{$prefix}btn-active-border-color);
    @include box-shadow(var(--#{$prefix}btn-active-shadow));

    &:focus-visible {

      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows {
        box-shadow: var(--#{$prefix}btn-active-shadow),
        var(--#{$prefix}btn-focus-box-shadow);
      }

      @else {
        box-shadow: var(--#{$prefix}btn-focus-box-shadow);
      }
    }
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    color: var(--#{$prefix}btn-disabled-color);
    pointer-events: none;
    background-color: var(--#{$prefix}btn-disabled-bg);
    background-image: if($enable-gradients, none, null);
    border-color: var(--#{$prefix}btn-disabled-border-color);
    opacity: var(--#{$prefix}btn-disabled-opacity);
    @include box-shadow(none);
  }
}


//
// Alternate buttons
//

// scss-docs-start btn-variant-loops
@each $color,
$value in $theme-colors {
  .btn-#{$color} {
    @if $color =="light" {
      @include button-variant($value,
        $value,
        $hover-background: shade-color($value, $btn-hover-bg-shade-amount),
        $hover-border: shade-color($value, $btn-hover-border-shade-amount),
        $active-background: shade-color($value, $btn-active-bg-shade-amount),
        $active-border: shade-color($value, $btn-active-border-shade-amount));
    }    

    @else if $color =="dark" {
      @include button-variant($value,
        $value,
        $hover-background: tint-color($value, $btn-hover-bg-tint-amount),
        $hover-border: tint-color($value, $btn-hover-border-tint-amount),
        $active-background: tint-color($value, $btn-active-bg-tint-amount),
        $active-border: tint-color($value, $btn-active-border-tint-amount));
    }

    @else {
      @include button-variant($value, $value);
    }

    // font-size: 0.9rem;  
    border-radius: 2rem;
    text-transform: capitalize;
    color: $white;
    padding: 0.34rem 0.8rem;
    line-height: normal;
    border: none;
    transition: all 0.4s ease;

    &:hover,
    &:focus {
      background-color: darken($primary, 8%);
    }
  }
  .btn-light {
    border-radius: 0.3rem !important;
    color: $black;
    width: 100%;
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 0.1rem;
    &.active {
      border: 1.5px solid $white;
      color: $white;
      background-color: rgb(255 255 255 / 18%);
      &:hover, &:focus {
        color: $white;
        background-color: rgb(255 255 255 / 18%);
      }
    }
    &:hover, &:focus {
      color: $white;
      background-color: $primary;
    }
  }
}

@each $color,
$value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
    background-color: transparent;
    border-radius: 2rem;
    font-size: 1rem;
    text-transform: capitalize;
    color: $white;
    padding: 0.6rem 2.8rem;
    line-height: 1;
    border: 1px solid #ffffff;
    transition: all 0.4s ease;

    &:hover,
    &:focus {
      background-color: $white;
      color: $primary;
    }
  }

  .btn-outline {
    border-color: #787878;
    color: #787878;
    font-size: 1rem;
    border-radius: 2rem;
    text-transform: capitalize;
    padding: 0.5rem 1rem;
    line-height: 1;

    &:focus,
    &:hover {
      background-color: #787878;
      color: $white;
    }
  }
}

// scss-docs-end btn-variant-loops


//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  --#{$prefix}btn-font-weight: #{$font-weight-normal};
  --#{$prefix}btn-color: #{$btn-link-color};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-color: transparent;
  --#{$prefix}btn-hover-color: #{$btn-link-hover-color};
  --#{$prefix}btn-hover-border-color: transparent;
  --#{$prefix}btn-active-color: #{$btn-link-hover-color};
  --#{$prefix}btn-active-border-color: transparent;
  --#{$prefix}btn-disabled-color: #{$btn-link-disabled-color};
  --#{$prefix}btn-disabled-border-color: transparent;
  --#{$prefix}btn-box-shadow: 0 0 0 #000; // Can't use `none` as keyword negates all values when used with multiple shadows
  --#{$prefix}btn-focus-shadow-rgb: #{to-rgb(mix(color-contrast($link-color), $link-color, 15%))};

  text-decoration: $link-decoration;

  @if $enable-gradients {
    background-image: none;
  }

  &:hover,
  &:focus-visible {
    text-decoration: $link-hover-decoration;
  }

  &:focus-visible {
    color: var(--#{$prefix}btn-color);
  }

  &:hover {
    color: var(--#{$prefix}btn-hover-color);
  }

  text-decoration: none;
  font-size: 0.7rem;
  background-color: transparent;
  padding: 0;

  &.edit-btn {
    color: $success;
    &:hover,
    &:focus {
      background-color: transparent;
      color: $primary;
    }
  }

  &.delete-btn {
    color: $danger;
    &:hover,
    &:focus {
      background-color: transparent;
      color: $primary;
    }
  }

  &.play-btn {
    svg {
      width: 1.7rem;
      height: 1.7rem;
      transition: all 0.3s ease;

      circle {
        color: $info;
      }

      path {
        color: $white;
      }
    }

    &:hover,
    &:focus {
      svg {
        circle {
          color: $primary;
        }

        path {
          color: $white;
        }
      }
    }
  }

  // No need for an active state here
}


//
// Button Sizes
//

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
}

.back-btn {
  border: 1px solid $yellow;
  color: $yellow;
  transition: all 0.2s ease-in;
  padding: 0.1rem 0.5rem 0.1rem 0.1rem;

  span {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 10rem;
    border: 1px solid $white;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $yellow;
    color: $white;
    transition: all 0.2s ease-in;

    svg {
      width: 75%;
      height: 75%;
    }
  }

  &:hover,
  &:focus {
    background: $yellow;
    color: $white;
    border: 1px solid $yellow;

    span {
      background-color: $white;
      border: 1px solid $yellow;
      color: $yellow;
    }
  }
}

.inner-wrapper {
  .btn-outline-primary {
    border-color: $primary;
    color: $primary;
    padding: 0.6rem 1.1rem;

    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
}

.front-wrapper {
  .btn-primary {
    background: linear-gradient(30deg, $primary 0%, $primary 31.43%, $primary 75.47%, $primary 100%);
    border-radius: 0.5625rem;
    color: $white !important;
    font-size: 1.375rem;
    padding: 0.6rem 1.6rem;
    font-weight: 600;
    transition: all 0.3s ease;
    &:hover, &:focus {
      background: linear-gradient(30deg, #6E5FCC 0%, #898DEA 31.43%, #A395F9 75.47%, #CFC7FF 100%);
    }
  }
}

@media screen and (max-width:1439px) {
  .front-wrapper {
    .btn-primary {        
        font-size: 1.075rem;
        padding: 0.5rem 1.3rem;
    }
  }
}
@media screen and (max-width: 639px) {
  .front-wrapper {
    .btn-primary {
      font-size: 0.875rem;
      padding: 0.3rem 0.8rem;
      border-radius: 0.4rem;
    }
  }
}