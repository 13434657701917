@import "mixins/banner";
@include bsBanner("");


// scss-docs-start import-stack
// Configuration
@import "functions";
@import "variables";
@import "variables-dark";
@import "maps";
@import "mixins";
@import "utilities";

// Layout & components
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "nav";
@import "navbar";
@import "card";
@import "accordion";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "alert";
@import "progress";
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "offcanvas";
@import "placeholders";

// Helpers
@import "helpers";

// Utilities
@import "utilities/api";

@import url(https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css);
// scss-docs-end import-stack

body {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.admin-layout {
    background-color: $primary;
    min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

ol,
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none;
    line-height: 1.4;
}

p {
    font-size: 0.9rem;
    line-height: normal;
    margin-bottom: 0;
}

img {
    width: 100%;
    max-width: 100%;
}

/*---------------Scroll-design--------------*/
::-webkit-scrollbar {
    width: 10px;
    background: rgba(161, 146, 255, 0.779);
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(110 95 204 / 13%);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #574ba2;
}

::-webkit-scrollbar-thumb:window-inactive {
    background: darken($primary, 20%);
}

.small-scroll {
    &::-webkit-scrollbar {
        width: 5px;
        background: rgba(161, 146, 255, 0.779);
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgb(110 95 204 / 13%);
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #574ba2;
    }

    &::-webkit-scrollbar-thumb:window-inactive {
        background: darken($primary, 20%);
    }
}

// pre-loader

.pre_loader{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: #0000005c;
    z-index: 999;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
        width: 4rem;
        height: 4rem;
        .wave{
            
            &:first-child{
                animation:audio-wave-1 2s linear infinite alternate-reverse both;
            }
            &:nth-child(2){
                animation:audio-wave-2 2s linear infinite alternate-reverse both;
            }
            &:nth-child(3){
                animation:audio-wave-1 2s linear infinite alternate-reverse both;
            }
        }
    }
}
@keyframes audio-wave-1 {
    0%{
        height: 44px;
    }
    25%{
        height: 88px;
    //   transform: scaleY(1.2);
    }
    50%{
        height: 110px;
    //   transform: scaleY(1.3);
    }
    70%{
        height: 88px;
        // transform: scaleY(1.2);
      }
    100%{
        height: 44px;
    //   transform:scaleY(1);
    }
  }
  @keyframes audio-wave-2 {
    0%{
        height: 110px;
    }
    25%{
        height: 88px;
    //   transform: scaleY(1.2);
    }
    50%{
        height: 44px;
    //   transform: scaleY(1.3);
    }
    70%{
        height: 88px;
        // transform: scaleY(1.2);
      }
    100%{
        height: 110px;
    //   transform:scaleY(1);
    }
  }


// container
.container {
    max-width: calc(100% - 10rem) !important;
    margin: 0 5rem;
    padding: 0;
}

.admin-layout {
    .form-control {
        background-color: unset;
        height: 2.2rem;
        color: $gray-700;
        font-size: 0.9rem;
        box-shadow: none;

        &::placeholder {
            color: $gray-500;
        }

        // &:not(:placeholder-shown) {
        //     border-color: $yellow;
        // }

        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
            -webkit-text-fill-color: $gray-700;
            caret-color: $black !important;
        }
    }

    .btn-primary {
        gap: 0.3rem;

        svg {
            width: 0.9rem;
            height: 0.9rem;
        }
    }
}



// login-page
.login-page {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;

    &::before {
        content: "";
        background-image: url('../assets/images/login-banner-shape.png');
        background-position: center;
        background-size: 96%;
        width: 100%;
        height: 100%;
        filter: opacity(30%);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        // animation: rotate 20s linear infinite;
    }

    .srv-validation-message {
        // color: rgb(220 0 0);
        color: rgb(255 161 161);
        margin-top: 0.3rem;
        font-size: 0.8rem;
    }

    .login-main {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        max-height: calc(100vh - 1.4rem);
        max-width: 30%;
        margin: 0 auto;
        overflow: hidden;
        overflow-y: auto;
        // filter: opacity(100%);
        // background-color: rgb(255 255 255 / 53%);
        // background-color: rgb(255 255 255 / 85%);
        background-color: $primary;
        padding: 1.6rem 1.3rem;
        border-radius: 0.6rem;
        text-align: center;
        z-index: 2;
        box-shadow: 0px 0px 8px 0px #9f9f9f;

        .form-control,
        input {

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0 30px #6E5FCC inset !important;
                -webkit-text-fill-color: #fff;
                caret-color: #fff !important;
            }
        }

        .main-logo {
            margin-bottom: 0.7rem;
        }

        .login-head {
            h2 {
                font-size: 1.8rem;
                color: $white;
                margin-bottom: 1rem;
            }

            h3 {
                color: $white;
                font-size: 1.3rem;
                margin-bottom: 0.5rem;
            }

            p {
                color: $white;
                margin-bottom: 1.3rem;
            }
        }

        form {
            text-align: left;

            .btn {
                display: block;
                margin: 0 auto;
            }

            .otp-box {
                .srv-validation-message {
                    text-align: center;
                }
            }

            .otp-box,
            .password-box,
            .slide-left-box {
                -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
                animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
                transition: all 0.3s ease;
            }

            &.slide-left-box {
                -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
                animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
                transition: all 0.3s ease;
            }
        }

        div.main-logo {
            color: $white;
            display: inline-block;
            transition: all 0.4s ease;
            font-size: 0.8rem;
            position: relative;
        }

        a {
            color: $white;
            display: inline-block;
            transition: all 0.4s ease;
            font-size: 0.8rem;
            position: relative;

            &::after {
                content: "";
                width: 0;
                height: 0.1rem;
                position: absolute;
                bottom: -0.1rem;
                background-color: $yellow;
                display: block;
                transition: all 0.3s ease;
            }

            &:hover {
                color: $yellow;

                &::after {
                    width: 100%;
                }
            }
        }

        .form-group {
            position: relative;
            margin-bottom: 0.7rem;

            label {
                color: #ffffff;
            }

            .form-control,
            input {
                color: #ffffff !important;

                &::placeholder {
                    color: rgba($white, 0.6) !important;
                }
            }

            .show-hide-password {
                background-color: unset;
                padding: 0;
                border: none;
                position: absolute;
                top: 2.8rem;
                transform: translateY(-50%);
                right: 2%;
                color: #d7d7d7;
                line-height: 1;

                &:hover {
                    color: $yellow;
                }

                &.hide-icon {
                    top: 2.9rem;
                }
            }

            .react-code-input {
                display: flex !important;
                justify-content: center;
                gap: 0.4rem;

                input {
                    background-color: transparent !important;
                    padding: 0.5rem;
                    line-height: normal;

                    &:focus-visible {
                        outline: none;
                        border: 1px solid $yellow !important;
                    }
                }
            }

            span {
                display: block;
                font-size: 0.8rem;
                color: $white;
                text-align: center;
                margin-top: 0.5rem;

                a {
                    color: $yellow;
                    text-decoration: none;
                    position: relative;
                    margin-left: 0.2rem;

                    &::after {
                        content: "";
                        width: 0;
                        height: 0.1rem;
                        position: absolute;
                        bottom: 0;
                        background-color: $yellow;
                        display: block;
                        transition: all 0.3s ease;
                    }

                    &:hover {
                        &::after {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .shape-box {
        img {
            z-index: 0;

            &:first-of-type {
                position: absolute;
                left: 0;
                width: auto;
                height: 75%;
                bottom: 0;
                animation: scale 2.7s alternate infinite;
            }

            &:nth-child(2) {
                position: absolute;
                left: 25%;
                width: auto;
                height: 29%;
                top: 0;
                animation: scale 2.7s alternate infinite;
            }

            &:last-of-type {
                position: absolute;
                right: 0;
                width: auto;
                height: 95%;
                bottom: 0;
                animation: scale 2.7s alternate infinite;
            }
        }
    }
}

.sidebar-main {
    width: 14rem;
    height: 100vh;
    border-radius: 15px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    transition: all 0.5s ease;
    animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

    .sidebar-logo {
        display: block;
        margin-top: 1rem;
        text-align: center;

        img {
            width: 7rem;
            height: 100%;

            &:last-of-type {
                display: none;
            }
        }
    }

    .nav {
        margin: 0;
        padding: 0 0.6rem;
        height: calc(100vh - 8.8rem);
        overflow: auto;
        flex-wrap: nowrap;
        display: flex;
        gap: 0.5rem;

        .nav-item {
            width: 100%;

            span {
                text-wrap: nowrap;
                // transition: all 0.3s ease-in-out;
            }

            &.active {
                >a {
                    background-color: $white;
                    color: $primary;

                    &:hover,
                    &:focus {
                        background-color: rgba($white, 0.25);
                        color: $white;
                    }
                }

                .dropdown-menu {
                    a {

                        &:hover,
                        &:focus {
                            background-color: rgba($white, 0.15);
                            color: $white;
                        }
                    }
                }
            }

            >a,
            button {
                padding: 0.6rem;
                color: $gray-300;
                display: flex;
                align-items: center;
                gap: 0.3rem;
                font-size: 0.92rem;
                font-weight: 400;
                border-radius: 0.4rem;
                transition: all 0.3s ease;

                svg {
                    width: 1.3rem;
                    height: 1.3rem;
                    flex: none;
                }

                &.active {
                    background-color: $white;
                    color: $primary;

                    &:hover,
                    &:focus {
                        background-color: $white;
                        color: $primary;
                    }
                }

                &:hover,
                &:focus {
                    background-color: rgba($white, 0.15);
                    color: $white;
                }
            }
        }

        .dropdown {
            position: relative;

            &.show {
                background: rgba($white, 0.15);
                border-radius: 5px;

                >a,
                button {
                    background-color: rgba($white, 0.25);
                    color: $white;
                }
            }

            .dropdown-item {
                padding: 0.2rem 0.6rem;
                gap: 0.4rem;
                display: flex;
                align-items: center;
                font-size: 0.9rem;
                border-radius: 0.2rem;
                color: $gray-300;

                &::before {
                    content: "";
                    display: block;
                    width: 0.45rem;
                    height: 0.45rem;
                    border-radius: 2px;
                    background-color: rgba($white, 0.5);
                    transition: all 0.3s ease;
                    margin-top: 0.1rem;
                }

                &.active,
                &:hover,
                &:focus {
                    &::before {
                        background-color: $white;
                    }

                    background-color: rgba($white, 0.15);
                    color: $white;
                }
            }

            .has-child {
                position: relative !important;
                background-color: unset !important;
                top: auto !important;
                transform: none !important;
                border: none !important;
                padding: 0.3rem;
                gap: 0.3rem;
                margin: 0;
                transition: all 0.3s ease-in-out;
            }

            .dropdown-arrow {
                width: 0.6rem;
                position: absolute;
                right: 0.6rem;
                top: 1.2rem;
                transform: translateY(-50%);
                transition: all 0.3s ease;
            }
        }
    }

    &.active {
        width: 4rem;

        .nav {
            .nav-item {

                a,
                .nav-link {
                    justify-content: center;
                }

                span {
                    position: absolute;
                    right: 160%;
                }

                .dropdown-arrow {
                    display: none;
                }
            }

            .has-child {
                display: none;
            }
        }

        .sidebar-logo {
            margin-bottom: 1.3rem;

            img {
                &:first-of-type {
                    display: none;
                }

                &:last-of-type {
                    display: block;
                    width: 2.5rem;
                    margin: 0 auto;
                }
            }
        }

        &:hover {
            width: 14rem;

            .nav {
                .nav-item {

                    a,
                    .nav-link {
                        justify-content: flex-start;
                    }

                    span {
                        position: relative;
                        right: auto;
                    }

                    .dropdown-arrow {
                        display: block;
                    }
                }

                .has-child {
                    display: block;
                }
            }

            .sidebar-logo {
                margin-bottom: 0;

                img {
                    &:first-of-type {
                        display: block;
                        margin: 0 auto;
                    }

                    &:last-of-type {
                        display: none;
                        width: 2.5rem;
                        margin: 0 auto;
                    }
                }
            }

            +.main-wrapper {
                width: calc(100% - 14rem);
                margin-left: 14rem;

                .main-header {
                    width: calc(100% - 14rem);
                }
            }
        }
    }
}

.main-wrapper {
    width: calc(100% - 14rem);
    margin-left: 14rem;
    min-height: calc(100vh - 2.45rem);
    display: inline-block;
    transition: all 0.5s ease;
    padding: 0;
    overflow: hidden;

    .main-header {
        width: calc(100% - 14rem);
        transition: all 0.5s ease;
        padding: 0.8rem 0.5rem;
        padding-right: 1.3rem;
        z-index: 9;
        position: fixed;
        left: auto;
        background-color: $primary;
        right: 0;
        top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

        .left-head {
            display: flex;
            align-items: center;

            .hamburger_btn {
                color: $white;
                border-radius: 50%;
                padding: 0%;
                width: 2.3rem;
                height: 2.3rem;
                border: none;
                background: transparent;
                margin-right: 0.8rem;

                &:hover,
                &:focus {
                    cursor: pointer;

                    label {
                        cursor: pointer;
                        -webkit-transition: all 0.3s ease-in-out;
                        -o-transition: all 0.3s ease-in-out;
                        transition: all 0.3s ease-in-out;
                        text-align: center;

                        span {
                            cursor: pointer;
                            background: $white;
                        }
                    }
                }

                &.active {
                    label {
                        span:nth-of-type(1) {
                            transform-origin: bottom;
                            transform: rotatez(45deg) translate(3px, 3.5px);
                        }

                        span:nth-of-type(2) {
                            transform-origin: top;
                            transform: rotatez(-45deg) translate(-3px, 0px);
                        }

                        span:nth-of-type(3) {
                            transform-origin: bottom;
                            width: 50%;
                            transform: translate(10px, -3px) rotatez(45deg);
                        }
                    }
                }

                label {
                    display: flex;
                    flex-direction: column;
                    margin: 0 auto;
                    width: 2rem;
                    height: 1.8rem;
                    border-radius: 0.3rem;
                    padding: 0.4rem;
                    background-color: #9b8aff;
                    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);

                    span {
                        background: $white;
                        border-radius: 10px;
                        height: 0.15rem;
                        margin-bottom: 4px;
                        transition: 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);

                        &:last-child {
                            margin: 0;
                        }

                        // &:nth-of-type(1) {
                        //     width: 100%;
                        // }

                        // &:nth-of-type(2) {
                        //     width: 70%;
                        // }

                        // &:nth-of-type(3) {
                        //     width: 40%;
                        // }
                    }

                    &:hover,
                    &:focus {
                        background-color: $white;

                        span {
                            background-color: #9b8aff;
                        }
                    }
                }
            }

            .page-title {
                margin: 0;
                font-size: 1.6rem;
                font-weight: 500;
                color: $white;
            }
        }

        .profile-main {
            >button {
                display: flex;
                align-items: center;
                background: unset;
                border: none;
                box-shadow: none;
                padding: 0;
                gap: 0.4rem;
                text-decoration: none;

                .profile_img {
                    span {
                        width: 2rem;
                        height: 2rem;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 1rem;
                        background-color: $white;
                        color: $primary;
                        font-weight: 500;
                        text-transform: uppercase;
                    }
                }

                .profile_text {
                    p {
                        margin: 0;
                        font-size: 1.1rem;
                        line-height: normal;
                        color: $white;
                        font-weight: 400;
                        text-transform: capitalize;
                    }
                }

                &::after {
                    content: "";
                    box-sizing: border-box;
                    height: 0.5rem;
                    width: 0.5rem;
                    margin-left: 0.5rem;
                    border-style: solid;
                    border-color: rgb(255 255 255 / 69%);
                    border-width: 0px 0.15rem 0.15rem 0px;
                    transform: rotate(45deg);
                    transition: border-width 150ms ease-in-out;
                }
            }

            .dropdown-menu {
                margin-top: 0.4rem;
                padding: 0.2rem 0rem;
                min-width: 100%;

                .dropdown-item {
                    padding: 0.4rem 1rem;
                    gap: 0.5rem;
                    display: flex;
                    align-items: center;
                    font-size: 0.9rem;

                    svg {
                        width: 1.2rem;
                        height: 1.2rem;
                    }
                }
            }
        }
    }

    .wrapper {
        background: url('../assets/images/bg-pattern.png'), $white;
        background-attachment: fixed;
        border-radius: 1rem;
        padding: 1rem;
        padding-right: 0.5rem;
        margin-right: 0.7rem;
        margin-top: 3.9rem;
        min-height: calc(100vh - 4.65rem);
        max-height: calc(100vh - 4.65rem);

        .inner-wrapper {
            overflow-y: auto;
            min-height: calc(100vh - 6.55rem);
            max-height: calc(100vh - 6.55rem);
            padding: 0.1rem;
            padding-right: 0.5rem;
        }

        .master-card-list {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            gap: 0.8rem;

            &.character-card-list {
                grid-template-columns: repeat(9, 1fr);

                .card {
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 12rem;

                    img {
                        height: auto;
                        margin-top: 1.8rem;
                        width: 65%;
                        border-radius: 0.4rem;
                        object-fit: unset;
                        border: none;
                    }
                }
            }

            &.theme-card-list {
                grid-template-columns: repeat(7, 1fr);

                .card {
                    padding: 0rem;
                    img {
                        border: none;
                    }
                    h5{
                        font-size: 0.9rem;
                        padding: 0.5rem 0.6rem;
                        color: $primary;
                        span {
                            color: $gray-500;
                            font-weight: 500;
                        }
                    }
                }
            }

            .card {
                padding: 0.5rem;
                position: relative;
                border: none;
                overflow: hidden;
                min-height: 14rem;
                box-shadow: 0 0 6px #c1c1c1bf;

                &:hover {
                    .card-data {
                        >.overlay {
                            left: 0;
                        }
                    }
                }

                img {
                    max-width: 100%;
                    max-height: 100%;
                    height: 100%;
                    object-fit: contain;
                    border-radius: 0.4rem;
                    // aspect-ratio: 4.5/2;         
                    border: 1px solid #f2f2f2;
                }

                .card-data {
                    z-index: 1;

                    .badge {
                        line-height: normal;
                        padding: 0.1rem 0.4rem;
                        font-weight: 500;
                        font-size: 0.7rem;
                        position: absolute;
                        top: 2%;
                        right: 2%;
                        z-index: 1;
                    }

                    .form-check {
                        min-height: auto;
                    }

                    .overlay {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        inset: 0 0;
                        top: 0;
                        left: 108%;
                        background-color: rgb(0 0 0 / 18%);
                        transition: all 0.3s ease;
                    }

                    .edit-btn {
                        background-color: $white;
                        padding: 0.4rem 0.4rem;
                        border-radius: 50%;
                        color: $orange;

                        svg {
                            width: 1.1rem;
                            height: 1.1rem;
                            margin-bottom: 0.1rem;
                        }

                        &:hover {
                            color: $primary;
                        }
                    }

                    .switch {
                        position: absolute;
                        top: 2% !important;
                        right: 2% !important;
                        z-index: 1;
                    }
                }

                h5 {
                    font-size: 1.1rem;
                    padding-top: 0.2rem;
                    color: $black;
                    text-transform: capitalize;
                }

                .switch-check {
                    height: 1.8rem;
                }

                .switch-btn {
                    height: 1.4rem;
                    top: 0.16rem;
                }
            }

            >div {
                display: flex;
            }
        }

        .switch {
            input[type="checkbox"] {
                display: none;
            }

            .switch-check {
                position: relative;
                display: block;
                width: 6.9rem;
                height: 1.6rem;
                background-color: $white;
                border: 1px solid #dee2e6;
                border-radius: 2.3rem;
                margin-bottom: 0;
                cursor: pointer;
            }

            .switch-btn {
                width: 3.65rem;
                height: 1.2rem;
                background-color: $danger;
                box-shadow: none;
                position: relative;
                top: 0.15rem;
                left: 3rem;
                border-radius: 2rem;
                transition: 0.4s ease left, 0.4s ease background-position;
                z-index: 2;
            }

            .active-switch,
            .deactive-switch {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: $black;
                font-size: 0.8rem;
                line-height: 1;
                margin-left: 0.513rem;
                font-weight: 500;
                z-index: 99;
            }

            .deactive-switch {
                margin-left: 3.275rem;
                color: $white;
            }

            input:checked+.switch-check .switch-btn {
                left: 0.2rem;
                background-position: -21.875rem 0;
                background-color: $success;
                width: 2.8rem;
            }

            input:checked+.switch-check .active-switch {
                color: $white;
            }

            input:checked+.switch-check .deactive-switch {
                color: $black;
            }
        }

        .srv-validation-message {
            color: $red;
            font-size: 0.9rem;
            margin-bottom: 0.3rem;
        }
    }

    &.active {
        width: calc(100% - 4rem);
        margin-left: 4rem;

        .main-header {
            width: calc(100% - 4rem);
        }
    }
}

.modal {
    .srv-validation-message {
        color: $red;
        font-size: 0.8rem;
        margin-top: 0.3rem;
        margin-bottom: 0.4rem;
    }
}

.gender-select-box {
    >div {
        display: flex;
        gap: 1rem;

        .form-check {
            padding-left: 0;
            margin-bottom: 0;
            text-align: center;
            cursor: pointer;

            &:hover {
                .gender-male {
                    border-color: #417df8;
                    background-color: #dce8ff;
                }

                .gender-female {
                    border-color: #ff5569;
                    background-color: #ffd3d9;
                }
            }
        }

        .form-check-input[type=radio] {
            opacity: 0;

            &:checked+.gender-male {
                border-color: #417df8;
                background-color: #dce8ff;
            }

            &:checked+.gender-female {
                border-color: #ff5569;
                background-color: #ffd3d9;
            }
        }

        label {
            margin-bottom: 0;
            border: 2px dashed transparent;
            border-radius: 2rem;
            padding: 0.3rem 0.4rem;

            svg {
                width: 2.5rem;
                height: 2.5rem;
            }
        }

        span {
            margin-top: 0.3rem;
            font-size: 0.9rem;
        }
    }
}

.support-user-modal {
    >div {
        display: flex;
        justify-content: space-between;
        gap: 0.3rem;

        .user-box {
            width: 2.6rem;
            height: 2.6rem;
            border-radius: 2rem;
            background-color: $primary;
            color: #ffffff;
            font-size: 1.2rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }

        >div {
            display: flex;
            align-items: center;
            gap: 0.4rem;

            h5 {
                font-size: 1.2rem;
                color: $black;
            }

            p {
                color: $gray-600;
            }

            &:last-of-type {
                display: flex;
                flex-direction: column;
                gap: 0.1rem;
                align-items: flex-end;

                p {
                    display: flex;
                    align-items: center;
                    gap: 0.3rem;

                    svg {
                        width: 1rem;
                        height: 1rem;
                    }
                }
            }
        }

        p {
            color: $gray-700;
        }
    }

    .message-box {
        background-color: lighten($secondary, 34%);
        // border-left: 4px solid $secondary;
        padding: 0.4rem;
        border-radius: 0.3rem;
        margin-top: 0.7rem;
    }
}

.usermanagement-view {
    padding: 0 0.2rem;

    .card {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row;
        padding: 1rem;
        box-shadow: 0px 0px 0.5rem #00000040;
        border: 0;
    }

    .parent-list {
        background: #c45fa42b;
        padding: 0.9rem;
        border-radius: 10px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;

        // position: relative;
        // &::after{
        //     content: "";
        //     background: url(../assets/images/parents_.png);
        //     background-repeat: no-repeat;
        //     background-size: contain;
        //     width: 8rem;
        //     height: 8rem;
        //     position: absolute;
        //     bottom: 0;
        //     right: 3%;
        //     opacity: 0.1;
        // }
        .card {
            border-left: 5px solid #c45fa4;
            flex-direction: column;
            position: relative;

            &:first-child {
                &::after {
                    content: "";
                    background: url(../assets/images/king.png);
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 5rem;
                    height: 5rem;
                    position: absolute;
                    bottom: 0;
                    right: 3%;
                    opacity: 0.3;
                }
            }
            &.sub-parent {
                .subparent-badge {
                    font-size: 0.76rem;
                    font-weight: 500;
                    background-color: #45ba8a;                    
                    color: $white;
                    padding: 0.3rem 0.6rem;
                    border-radius: 1rem;
                    display: inline-block;
                    margin-top: 0.2rem;
                }
            }
            // .parent-head-3{
            //     display: flex;
            //     justify-content: space-between;                
            //     gap: 0.6rem;
            //     width: 100%;      
            //     align-items: flex-start;
            //     svg{
            //         width: 0.9rem;
            //     }          
            // }
            // .parent-date-3{
            //     width: 100%;
            //     text-align: center;
            //     margin-top: 0.6rem;                
            //     svg{
            //         width: 0.6rem;
            //         height: 1rem;
            //     }
            //     p {
            //         justify-content: center;
            //     }
            //     .date-gender-box {
            //         display: flex;
            //         justify-content: center;
            //         align-items: center;
            //         gap: 0.4rem;
            //         margin-top: 0.6rem;                
            //     }
            //     .bg-info {
            //         font-size: 0.8rem;                    
            //         display: flex;
            //         gap: 0.3rem;
            //         color: #ffffff;
            //         justify-content: center;
            //         align-items: center;
            //     }
            // }

            .parent-head {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;

                svg {
                    width: 0.9rem;
                }
            }

            .parent-date {
                width: 100%;
                margin-top: 0.4rem;

                .date-gender-box {
                    display: flex;
                    align-items: center;
                    gap: 0.4rem;
                    margin-top: 0.5rem;
                }

                svg {
                    width: 1.4rem;
                }

                >div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .gender-box {
                    font-size: 0.8rem;
                    display: flex;
                    gap: 0.3rem;
                    line-height: 1;
                    padding: 0.2rem 0.4rem;                    
                    justify-content: center;
                    align-items: center;                    

                    svg {
                        width: 0.6rem;
                        height: 1rem;
                    }
                    &.bg-info {
                        color: darken($secondary,20%);
                        border: 1px dashed darken($secondary,20%);
                        background-color: rgba($secondary, 0.13) !important;
                    }
                    &.bg-pink {
                        color: $pink;
                        border: 1px dashed $pink;
                        background-color: rgba($pink, 0.13);
                    }
                }

                .manual-type {
                    width: 1.4rem;
                    height: 1.4rem;
                    display: inline-flex;
                    border-radius: 50%;
                    background-color: $primary;
                    color: $white;
                    font-weight: 400;
                    align-items: center;
                    justify-content: center;
                    line-height: 1;
                }
            }

            // .parent-head-2 {
            //     width: 100%;
            //     .user-box {
            //         width: 3.4rem;
            //         height: 3.4rem;
            //         margin-bottom: 0.3rem;
            //     }
            //     > div {
            //         &:nth-child(2) {
            //             display: flex;
            //             justify-content: space-between;                        
            //         }
            //     }
            // }
            // .parent-date-2 {
            //     display: flex;
            //     justify-content: space-between;
            //     width: 100%;
            //     align-items: center;
            //     margin-top: 0.6rem;
            //     svg{
            //         width: 0.9rem;
            //     }
            // }
            .user-box {
                // width: 2.5rem;
                // height: 2.5rem;
                // border-radius: 3rem;
                // background-color: lighten($primary, 30%);
                // color: $primary;
                // display: flex;
                // align-items: center;
                // justify-content: center;
                // position: relative;
                // font-size: 1.1rem;
                // font-weight: 500;

                span {
                    position: absolute;
                    top: -1%;
                    right: -2%;
                    padding: 0.1rem 0.1rem;
                    align-items: center;
                    color: $yellow;
                    background-color: lighten($yellow, 17%);
                    border-radius: 3rem;
                    display: none;

                    svg {
                        width: 0.8rem;
                        height: 0.9rem;
                    }
                }
            }

            h4 {
                color: $black;
                font-size: 1.2rem;
            }

            p {
                color: #868b8f;
                font-size: 0.8rem;
                display: flex;
                align-items: center;
                gap: 0.2rem;
            }

            .child-box {
                padding: 0.15rem 0.4rem;
                color: $orange;
                background-color: #ffb58f73;
                border: 1px dashed $orange;
                display: flex;
                gap: 0.3rem;
                align-items: center;
                font-weight: 400;
                width: max-content;
                height: fit-content;

                svg {
                    width: 1rem;
                    height: 1.2rem;
                }
            }

            span {
                color: $gray-700;
                font-weight: 500;
                align-items: center;
                display: flex;
                gap: 0.3rem;
            }
        }

    }

    .child-list {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        // padding: 1rem 0 0 1rem;        
        gap: 1rem;
        margin-top: 1rem;

        .card {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 0;
            background: #fff2e7;
            border: 1px solid #fff;

            .child-detail {
                text-align: center;

                .img-box {
                    max-width: 5rem;
                    height: 5rem;
                    overflow: hidden;
                    border: 2px solid #ff8f22;
                    box-shadow: 0rem 0rem 0.7rem 0.1rem #00000045;
                    flex: none;
                    border-radius: 50%;
                    margin: 0 auto;
                    margin-bottom: 0.5rem;
                    background-color: #f3f3f3;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                p {
                    color: $black;
                    display: flex;
                    align-items: center;
                    gap: 0.3rem;
                    justify-content: center;

                    svg {
                        color: $orange;
                    }
                }

                h4 {
                    font-size: 1.1rem;
                    margin-bottom: 0.2rem;
                    color: #de6f04;
                }

                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 0.6rem;

                    p {
                        margin-bottom: 0;
                    }
                }
            }

            p {
                color: #696b6c;
                font-size: 0.8rem;
                margin-bottom: 0.2rem;

                svg {
                    width: 0.9rem;
                    height: 0.9rem;
                }
            }

            img {
                height: 5rem;
                width: auto;
            }

            .switch {
                flex-basis: auto;
            }

            .badge {
                border-radius: 3rem;
                padding: 0.24rem 0.3rem;
                height: fit-content;
                align-items: center;
                display: flex;
                gap: 0.1rem;
                font-weight: 400;

                svg {
                    width: 0.9rem;
                    height: 0.9rem;
                }
                &.bg-info {
                    color: darken($secondary,20%);
                    border: 1px dashed darken($secondary,20%);
                    background-color: rgba($secondary, 0.13) !important;
                }
                &.bg-pink {
                    color: $pink;
                    border: 1px dashed $pink;
                    background-color: rgba($pink, 0.13);
                }
            }

            .child-data {
                width: 100%;
                margin-bottom: 0 !important;
                padding: 0.7rem;
                padding-bottom: 0;

                .child-data-head {
                    display: flex;
                    justify-content: space-between;
                }
            }

            .child-data-footer {
                width: 100%;
                display: flex;
                position: relative;
                padding: 0.2rem 0.5rem;
                border-top: 1px dashed #bebebe;
                margin-top: 1rem;
                justify-content: space-around;

                &::before {
                    content: "";
                    display: block;
                    background-color: #cbcbcb;
                    width: 1px;
                    height: 100%;
                    position: absolute;
                    left: 50%;
                    top: 0;
                }

                p {
                    font-size: 0.82rem;
                    text-align: center;
                    margin-bottom: 0;
                    flex-basis: 50%;

                    b {
                        color: #323231;
                        display: block;
                    }
                }
            }
        }
    }
}

.coupons-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.8rem;

    .coupon {
        overflow: hidden;
        border-radius: 10px;
        background-color: #dce8ff;
        display: flex;
        // -webkit-mask-image: radial-gradient(circle at 22rem 0.3rem, transparent 0.4rem, red 4.5px), radial-gradient(closest-side circle at 50%, red , transparent 100%);
        // -webkit-mask-size: 100%, 0.2rem 0.3rem;
        // -webkit-mask-repeat: repeat, repeat-y;
        // -webkit-mask-position: 0 -5.9rem, 21.9rem;
        // -webkit-mask-composite: source-out;
        // mask-composite: subtract;
        z-index: 0;

        // filter: drop-shadow(5px 5px 10px #666);
        .coupon-left {
            flex-basis: 90%;
            position: relative;
            padding: 0.8rem;
            border-right: 2px dashed $white;
            display: flex;
            gap: 0.6rem;

            &::before,
            &::after {
                content: "";
                position: absolute;
                display: block;
                width: 1.5rem;
                height: 1.5rem;
                background-color: $white;
                border-radius: 50%;
                right: -0.9rem;
                top: -18%;
            }

            &::after {
                top: auto;
                bottom: -18%;
            }

            .dis-box {
                width: 4rem;
                height: 4rem;
                background-color: #7c9edd;
                border-radius: 0.4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                p {
                    font-size: 1.3rem;
                    color: $white;
                    line-height: 1;
                    font-weight: 600;
                }

                span {
                    font-size: 0.9rem;
                    color: $white;
                    margin-top: 0.1rem;
                }
            }

            >div {
                &:last-of-type {
                    flex: 1;

                    p {
                        color: #435572;
                        font-size: 1rem;
                    }
                }
            }

            .coupon-detail {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                span {
                    color: #7c9edd;
                    padding: 0.2rem;
                    border: 1px dashed #7c9edd;
                    border-radius: 0.3rem;
                    margin-bottom: 0.4rem;
                    font-weight: 500;
                    display: inline-block;
                    background-color: $white;
                    font-size: 1.1rem;
                }

                .coupon-date {
                    display: flex;
                    align-items: center;
                    gap: 0.2rem;
                    color: #475c85;
                    font-size: 0.9rem;

                    svg {
                        width: 1rem;
                        height: 1rem;
                    }
                }
            }
        }

        .coupon-con {
            flex-basis: 10%;
            padding: 0.8rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            >div {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
            }

            h5 {
                font-size: 1.4rem;
                line-height: 1;
            }

            .edit-btn {
                background-color: $white;
                padding: 0.2rem 0.1rem;
                border-radius: 0.3rem;
                height: fit-content;

                svg {
                    width: 1rem;
                    height: 1rem;
                }
            }
        }

        &:nth-child(5n+2) {
            background-color: lighten($success, 33%);

            .dis-box {
                background-color: $success;
            }

            .coupon-detail {
                span {
                    color: $success;
                    border-color: $success;
                }
            }
        }

        &:nth-child(5n+3) {
            background-color: lighten($yellow, 33%);

            .dis-box {
                background-color: $yellow;
            }

            .coupon-detail {
                span {
                    color: $yellow;
                    border-color: $yellow;
                }
            }
        }

        &:nth-child(5n+4) {
            background-color: lighten($orange, 53%);

            .dis-box {
                background-color: $orange;
            }

            .coupon-detail {
                span {
                    color: $orange;
                    border-color: $orange;
                }
            }
        }

        &:nth-child(5n+5) {
            background-color: lighten($primary, 33%);

            .dis-box {
                background-color: $primary;
            }

            .coupon-detail {
                span {
                    color: $primary;
                    border-color: $primary;
                }
            }
        }
    }
}

.swal-modal {
    .swal-icon {
        border-color: $primary !important;

        .swal-icon--warning__body,
        .swal-icon--warning__dot {
            background-color: $primary !important;
        }
    }

    .swal-title {
        color: $black;
        padding: 0 1rem;
        font-size: 1.4rem;
    }

    .swal-footer {
        text-align: center;

        .swal-button-container {
            margin: 0;

            .swal-button {
                border-radius: 2rem;

                &:focus {
                    box-shadow: none;
                }
            }

            .swal-button--danger {
                background-color: $primary;
                margin-left: 0.7rem;
            }
        }
    }
}

.lyric-box {
    margin-bottom: 1rem;
    max-height: 25rem;
    overflow: hidden auto;
    padding: 0.4rem;
    padding-right: 0.4rem;
    font-size: 1rem;

    .lyrics {
        margin-bottom: 0.5rem;
        transition: all 0.4s ease;
        color: #c5c5c5;
        transform: scale(0.98);

        &.lyrics:has(+ .active),
        &.active+.lyrics,
        &.active {
            color: $info;
            font-weight: 600;
        }

        &.active {
            color: darken($info, 10%);
            transform: scale(1.03);
        }

        &.lyrics:has(+ .active),
        &.active+.lyrics {
            opacity: 0.7;
            transform: scale(1.01);
        }
    }
}


// email template

.template-box {
    position: relative;

    .card {
        position: sticky;
        top: 5.7rem;
    }
}

.Default_notifications {
    .card {
        position: sticky;
        top: 0rem;
        height: auto;
        display: inherit;
        overflow: hidden;
        margin: 0;

        .card-header {
            padding: 0.5rem 0.7rem;
            background-color: lighten($primary, 33%);

            h4 {
                margin: 0;
                font-size: 1.1rem;
                color: $primary;
            }
        }

        .card-body {
            padding: 0;

            ul {
                margin: 0;
                padding: 0.6rem 0.7rem;
                gap: 0.3rem;
                list-style-type: none;
                display: flex;
                flex-direction: column;
                height: calc(100vh - 30rem);
                overflow: auto;

                li {
                    a {
                        width: 100%;
                        text-align: left;
                        padding: 0.5rem 0.5rem 0.5rem 1.5rem;
                        font-size: 0.9rem;
                        position: relative;
                        text-decoration: none !important;
                        transition: all 0.3s ease;
                        white-space: pre;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        border-radius: 0.3rem;
                        display: block;
                        border: none;

                        &::before {
                            content: '';
                            background-color: #000;
                            width: 0.4rem;
                            height: 0.4rem;
                            border-radius: 50%;
                            position: absolute;
                            top: 50%;
                            left: 0.5rem;
                            transform: translateY(-50%);
                        }

                        &:hover,
                        &:focus {
                            background: rgba($primary, 0.11);
                            border: none;
                        }

                        &.active {
                            background: $primary;
                            color: $white;

                            &::before {
                                background-color: $white;
                            }
                        }
                    }
                }
            }

            &.insert_placeholder {
                ul {
                    li {
                        padding: 0.4rem 0.5rem;
                        background: #00000008;
                        transition: all 0.3s ease;
                        border-radius: 5px;
                        font-size: 0.8rem;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        &:hover,
                        &:focus {
                            background: rgba($primary, 0.11);
                        }

                        .btn {
                            padding: 0.2rem;
                            background: transparent;
                            border: none;
                            transition: all 0.3s ease;
                            // box-shadow: 0.1rem 0.1rem 0.5rem #00000013;
                            color: $success;

                            &:hover,
                            &:focus {
                                color: $primary;
                            }
                        }
                    }
                }
            }
        }
    }
}

.edit_template {
    .nav {
        background: transparent;
        display: flex;
        gap: 0.4rem;
        margin: 0;
        overflow: auto;
        flex-wrap: nowrap;
        border: 0;

        .nav-item {
            a {
                text-decoration: none;
                background: #00000012;
                border-radius: 5px 5px 0 0;
                padding: 0.3rem 0.7rem;
                color: #262626;
                font-size: 1rem;
                white-space: pre;
                height: 100%;
                display: block;

                &:hover {
                    background: #4f2d7f29;
                    color: $primary;
                }

                &.active {
                    // background: $primary;
                    // color: $white;
                }

                &.show {
                    background: $primary;
                    color: $white;
                }
            }
        }
    }

    .card {
        border-radius: 0 0 8px 8px;
        margin-bottom: 2rem;

        .card-header {
            padding: 0.5rem;
            border-bottom: none;

            .form-group {
                margin-bottom: 0;
                display: inline-flex;
                align-items: center;
                gap: 0.6rem;

                .form-control {
                    font-size: 1.5rem;

                    &:disabled {
                        border: none;
                        color: $primary;
                        background-color: transparent;
                        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
                    }
                }

                .btn {
                    padding: 0;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    height: 2rem;
                    width: 2rem;
                    background: transparent;
                    color: $primary;
                    border: none;

                    &:hover,
                    &:focus {
                        color: $success !important;
                    }
                }
            }
        }

        .card-body {
            .tab-content {
                border-radius: 0 0 8px 7px;
                padding: 0.2rem;

                .form-control {
                    height: 2.5rem;
                }

                span {
                    color: #393939;
                }
            }
        }

        .form-check {
            font-size: 0.9rem;
            color: #435572;
        }

        .no-floating-label {
            label {
                color: $black;
                font-weight: 400;
            }
        }        
    }

    .form-switch {
        label {
            font-size: 0.9rem;
        }

        .form-check-input {
            width: 2.3em;
            height: 1.1em;
        }
    }
}

.jodit-add-new-line {
    display: none !important;
}

.coming-soon-page {
    position: relative;
    border: none;
    align-items: center;
    min-height: calc(100vh - 6.6rem);
    width: 100%;
    margin: 0 auto;
    padding: 0 4rem;
    overflow: hidden;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;

    .cloud-img {
        position: absolute;
        bottom: -19%;
        left: -0.3rem;
        right: 0;
        width: 100%;
        overflow: hidden;
        color: lighten($primary, 28%);

        svg {
            width: auto;
            height: 26rem;
        }
    }

    h2 {
        font-size: 3.4rem;
        margin-bottom: 1rem;
        letter-spacing: 0.4rem;
        color: $yellow;

        span {
            color: $yellow;
        }
    }

    p {
        margin-bottom: 1.4rem;
        font-size: 1.2rem;
        letter-spacing: 0.13rem;
    }

    .img-box {
        width: auto;
        height: auto;
        position: relative;
        margin: 0 auto;

        img {
            width: 11rem;
            display: block;
            margin: 0 auto;
            // box-shadow: 0 0 12px #f1f1f1;
            filter: drop-shadow(0 0 0.45rem $gray-600);

            &:first-child {
                width: 6.5rem;
                position: absolute;
                top: 22%;
                left: 20.3%;
                z-index: 1;
                animation: rotate 12s linear infinite;
            }

            &:nth-child(2) {
                position: absolute;
                width: calc(100% + 40%);
                max-width: none;
                left: 50%;
                top: 44%;
                animation: up-down 1s infinite alternate;
            }
        }
    }

    .inner-content {
        text-align: left;
        z-index: 1;
    }
}



.support-tickets-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.8rem;
    margin: 0 0.4rem;
    list-style: none;
    padding: 0;

    li {
        padding: 1rem;
        border-radius: 0.4rem;
        box-shadow: 0 0 5px 0px #c9c9c9;
        position: relative;
        background-color: $white;

        // &::before {
        //     content: "";
        //     border: 2px dashed #7c9edd;
        //     border-radius: 0.4rem;            
        //     right: 0.7rem;
        //     left: 0.7rem;
        //     top: 0.7rem;
        //     bottom: 0.7rem;
        //     position: absolute;
        // }
        >div {
            display: flex;
            justify-content: space-between;
            gap: 0.3rem;

            .user-box {
                width: 2.6rem;
                height: 2.6rem;
                border-radius: 2rem;
                background-color: #7c9edd;
                color: #ffffff;
                font-size: 1.2rem;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }

            >div {
                display: flex;
                align-items: center;
                gap: 0.4rem;

                h5 {
                    font-size: 1.2rem;
                    color: $black;
                }

                p {
                    max-width: 15rem;
                    color: $gray-600;
                    white-space: pre;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    border-radius: 0.3rem;
                    display: block;
                }

                &:last-of-type {
                    display: flex;
                    flex-direction: column;
                    gap: 0.3rem;
                    align-items: flex-end;
                    flex: none;

                    p {
                        display: flex;
                        align-items: center;
                        gap: 0.2rem;
                        line-height: 1;

                        svg {
                            width: 1rem;
                            height: 1rem;
                        }
                    }
                }
            }

            p {
                color: $gray-700;
            }
        }

        .message-box {
            border-radius: 0.3rem;
            margin-top: 0.7rem;
            font-size: 0.93rem;
            font-weight: 400;
            // -webkit-box-orient: vertical;
            // -webkit-line-clamp: 5;                
            // display: -webkit-box;            
            // line-height: 1.4;            
            // overflow: hidden;
            // white-space: pre-wrap;
        }

        // &:nth-child(5n+2) {
        //     &::before {               
        //         border-color: $success;                
        //     }
        //     .user-box {               
        //         background-color: $success;                           
        //     }
        // }
        // &:nth-child(5n+3) {
        //     &::before {               
        //         border-color: $yellow;                
        //     }
        //     .user-box {               
        //         background-color: $yellow;                           
        //     }
        // }
        // &:nth-child(5n+4) {
        //     &::before {               
        //         border-color: $orange;                
        //     }
        //     .user-box {               
        //         background-color: $orange;                           
        //     }
        // }
        // &:nth-child(5n+5) {
        //     &::before {               
        //         border-color: #7c9edd;                
        //     }
        //     .user-box {               
        //         background-color: #7c9edd;                           
        //     }
        // }
    }
}

// 404 page
.error-page {
    background-image: url('../assets/images/error-404-banner.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh - 6.6rem);
    display: flex;
    flex-direction: column;
    padding-top: 11rem;
    padding-left: 10rem;
    border-radius: 0.5rem;

    .error-content {
        width: fit-content;
        text-align: center;

        h1 {
            font-size: 8rem;
            color: #ed793b;
            line-height: 1.1;
            margin-bottom: 1.3rem;

            span {
                display: block;
                font-size: 2.3rem;
                color: #606060;
            }
        }

        p {
            font-size: 1.3rem;
            color: #6e6e6e;
        }

        .btn-primary {
            background-color: #ed793b;
            margin: 0 auto;

            &:hover,
            &:focus {
                background-color: darken(#ed793b, 15%);
            }
        }
    }
}

.show-more-link {
    color: $yellow;
    font-size: 0.9rem;
    cursor: pointer;

    &:hover {
        color: $primary;
    }
}



@keyframes wavy-slide {
    to {
        margin-left: -51%;
    }
}

@keyframes slide-left {
    0% {
        transform: translateX(20%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-right {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes scale {
    0% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes slide-bottom {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

//   @keyframes glow {
//     0%, 100% {
//       box-shadow: 1px 0px 19px 4px #fff503, inset 0px 0px 10px rgba(255, 255, 255, 0.5); }

//     50% {
//       box-shadow: 0px 0px 0px 0px rgba(255, 245, 3, 0), inset 0px 0px 0px rgba(255, 255, 255, 0); } 
//     }
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes up-down {
    0% {
        transform: translateY(0) translateX(-50%);
    }

    100% {
        transform: translateY(-10px) translateX(-50%);
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

[type=search]::-webkit-search-cancel-button{
    cursor: pointer;
}

.subscription-plans-box {    
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
    > div {       
        &:first-child {
            .card {                
                padding: 0;    
                background-color: unset !important;   
                overflow: hidden;                      
                .plan-form, .add-subscription-box {
                    background-color: lighten($secondary, 37%) !important;
                    padding: 1rem;
                    border: 2px dashed $primary;                    
                    span {
                        font-size: inherit;
                        margin-bottom: 0;
                    }
                }                
            }
        }        
            .card {
                // background: linear-gradient(48deg, #6E5FCC 0%, #898DEA 38.27%, #A395F9 63.54%, #CFC7FF 100%);
                background-color: $primary;
                height: 100%;
                border: none;
                padding: 1rem;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                .edit-btn {
                    margin-left: auto;
                    width: 1.9rem;
                    height: 1.9rem;
                    border-radius: 3rem;
                    background-color: $white;            
                    svg {
                        width: 1.2rem;
                        height: 1.2rem;
                    }
                }       
                ul {            
                    li {
                        font-size: 1rem;
                        position: relative;
                        padding-left: 1.3rem;
                        margin-bottom: 0.8rem;
                        color: $white;
                        &:last-of-type {
                            margin-bottom: 0;                    
                        }
                        &::after {
                            content: "";
                            background-image: url(../assets/images/tick.svg);
                            background-position: center;
                            background-size: 0.58rem;
                            background-repeat: no-repeat;
                            width: 1rem;
                            height: 1rem;
                            border-radius: 3rem;
                            position: absolute;
                            left: 0;
                            top: 55%;
                            transform: translateY(-50%);
                            background-color: $white;
                        }
                    }
                }                                
                h3 {
                    font-size: 1.6rem;
                    margin-bottom: 0.4rem;                    
                    color: $white;
                }
                span {
                    font-size: 2.8rem;
                    color: $white;
                    font-weight: 500;
                    margin-bottom: 1rem;
                    display: inline-block;
                }
            }                                
        &:nth-child(3n+1) {
            .card {
                background-color: $primary;
            }
        }        
        &:nth-child(3n+2) {
            .card {
                background-color: $success;
            }
        }   
        &:nth-child(3n+3) {
            .card {
                background-color: $secondary;
            }
        }        
        &:nth-child(3n+4) {
            .card {
                background-color: $primary;
            }
        } 
        &:nth-child(3n+5) {
            .card {
                background-color: $success;
            }
        }                       
        &:not(:nth-child(1)) {
            .card {
                position: relative;
                overflow: hidden;
                 > div {
                     &:last-of-type {
                         display: flex;
                         justify-content: space-between;
                         align-items: center;
                         margin-top: 1rem;
                     }
                 }  
                 &::after {
                    content: "";
                    width: 12rem;
                    height: 12rem;
                    background-color: #ffffff33;
                    position: absolute;
                    right: -20%;
                    top: -14%;
                    border-radius: 50%;
                 }
                 &::before {
                    content: "";
                    width: 10rem;
                    height: 10rem;
                    background-color: #ffffff33;
                    position: absolute;
                    right: -27%;
                    top: 16%;
                    border-radius: 50%;
                 }
            }  
         }  
    }      
    .add-subscription-box {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center !important;
        min-height: 18rem;
        height: 100%;
        margin-top: 0 !important;
        cursor: pointer;
        gap: 0.5rem;
        h3 {            
            color: $primary !important;            
            font-size: 1.5rem !important;
            margin-bottom: 0 !important;
        }
        svg {
            width: 2.6rem;
            height: 2.6rem;
            color: $primary;
        }
        &:hover, &:focus {                            
            border: 2px dashed $yellow !important;
            svg {                                
                color: $yellow;
            }
            h3 {
                color: $yellow !important;
            }
        }
    }
}
.subscription-items-box {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(5, 1fr);
    > div {                
            .card {
                // background: linear-gradient(48deg, #6E5FCC 0%, #898DEA 38.27%, #A395F9 63.54%, #CFC7FF 100%);
                background-color: lighten($primary, 13%);
                height: 100%;
                padding: 1rem;
                border: none;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                .edit-btn {
                    margin-left: auto;
                    width: 1.9rem;
                    height: 1.9rem;
                    border-radius: 3rem;
                    background-color: $white;            
                    svg {
                        width: 1.2rem;
                        height: 1.2rem;
                    }
                }       
                ul {            
                    li {
                        font-size: 1rem;
                        position: relative;
                        padding-left: 1.3rem;
                        margin-bottom: 0.8rem;
                        color: $white;
                        &:last-of-type {
                            margin-bottom: 0;                    
                        }
                        &::after {
                            content: "";
                            background-image: url(../assets/images/tick.svg);
                            background-position: center;
                            background-size: 0.58rem;
                            background-repeat: no-repeat;
                            width: 1rem;
                            height: 1rem;
                            border-radius: 3rem;
                            position: absolute;
                            left: 0;
                            top: 55%;
                            transform: translateY(-50%);
                            background-color: $white;
                        }
                    }
                }                                  
                h3 {
                    font-size: 1.6rem;
                    margin-bottom: 0.4rem;                    
                    color: $white;
                }
                span {
                    font-size: 2.8rem;
                    color: $white;
                    font-weight: 500;
                    margin-bottom: 1rem;
                    display: inline-block;
                }                
            }  
            &:first-child {
                .card {                    
                    padding: 0;     
                    background-color: transparent !important; 
                    overflow: hidden;              
                    .plan-form, .add-subscription-box {
                        padding: 1rem;
                        background-color: lighten($secondary, 37%) !important;
                        border: 2px dashed $primary;                        
                        span {
                            font-size: inherit;
                            margin-bottom: 0;
                        }                        
                    }                    
                }
            }
            &:nth-child(4n+2) {
                .card {
                    background-color: lighten($success, 10%);
                }
            }
            &:nth-child(4n+3) {
                .card {
                    background-color: lighten($secondary, 10%);
                }
            }   
            &:nth-child(4n+4) {
                .card {
                    background-color: lighten($yellow, 10%);
                }
            }
            &:nth-child(4n+5) {
                .card {
                    background-color: lighten($info, 13%);
                }
            }
            &:nth-child(4n+2) {
                .card {
                    background-color: lighten($primary, 10%);
                }
            }
            &:not(:nth-child(1)) {
                .card {
                    position: relative;
                    overflow: hidden;
                     > div {
                         &:last-of-type {
                             display: flex;
                             justify-content: space-between;
                             align-items: center;
                             margin-top: 1rem;
                         }
                     }  
                     &::after {
                        content: "";
                        width: 8rem;
                        height: 8rem;
                        background-color: #ffffff33;
                        position: absolute;
                        right: -12%;
                        top: -25%;
                        border-radius: 50%;
                     }
                     &::before {
                        content: "";
                        width: 6rem;
                        height: 6rem;
                        background-color: #ffffff33;
                        position: absolute;
                        right: -19%;
                        top: 18%;
                        border-radius: 50%;
                     }
                }  
             }  
              
    }    
    .add-subscription-box {
        display: flex;
        align-items: center;
        justify-content: center !important;
        flex-direction: column;
        gap: 0.5rem;
        min-height: 12.5rem;
        height: 100%;
        margin-top: 0 !important;
        cursor: pointer;
        h3 {            
            color: $primary !important;            
            font-size: 1.5rem !important;
            margin-bottom: 0 !important;
        }
        svg {
            width: 2.6rem;
            height: 2.6rem;
            color: $primary;
        }
        &:hover, &:focus {                            
            border: 2px dashed $yellow !important;
            svg {                                
                color: $yellow;
            }
            h3 {
                color: $yellow !important;
            }
        }
    }
}
.subscription-form {
    // .form-group {
    //     display: flex;
    //     align-items: center;             
    // }
    .btn-box {
        display: flex;
        align-items: center;
        gap: 0.3rem;
        justify-content: center;
    }    
}

.report-view-story {
    p {
        letter-spacing: 0.06rem;
    }
}

// rating page

.rate-data {
    .rate-total-head {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;        
        margin-bottom: 1rem;
        align-items: center;
        background-color: #cfdaff;
        padding: 1rem 0;
        border-radius: 0.6rem;
        > li {
            padding: 0 1rem;
            h5 {
                font-size: 1.3rem;
                color: $black;
                margin-bottom: 0.2rem;
            }
            span {
                font-size: 2.5rem;
                font-weight: 500;
                color: $primary;
                display: inline-block;
                margin-bottom: 0.3rem;
            }
            p {
                font-size: 1rem;
                color: #767676;
            }
            .rate-star-head {
                display: flex;
                gap: 0.5rem;
                align-items: center;
                .rating-star {
                    display: flex;
                    align-items: center;
                    gap: 0.4rem;
                    svg {
                        width: 1.2rem;
                        height: 1.2rem;                                                  
                    }
                    &.primary {
                        color: $primary;
                       svg {
                        path {
                            stroke: darken($primary, 10%);
                        }  
                       }
                    }
                    &.success {
                        color: $success;
                        svg {
                            path {
                                stroke: darken($success, 10%);
                            }  
                           }
                    }
                    &.info {
                        color: $info;
                        svg {
                            path {
                                stroke: darken($info, 10%);
                            }  
                           }
                    }
                    &.warning {
                        color: $warning;
                        svg {
                            path {
                                stroke: darken($warning, 10%);
                            }  
                           }
                    }
                    &.danger {
                        color: $danger;
                        svg {
                            path {
                                stroke: darken($danger, 10%);
                            }  
                           }
                    }
                }
            }
            &:not(:last-child) {
                border-right: 1px solid $gray-500;
            }
            .rate-list {
                li {
                    display: flex;
                    gap: 0.8rem;
                    align-items: center;
                    margin-bottom: 0.5rem;
                    span {
                        display: flex;
                        align-items: center;
                        gap: 0.3rem;
                        font-size: 1rem;
                        margin-bottom: 0;
                        svg {
                            width: 0.8rem;
                            height: 0.8rem;
                            path {
                                stroke: $primary;
                                fill: $white;
                            }
                        }
                    }
                    .progress {
                        width: 11rem;
                        height: 0.4rem;                        
                    }
                    .total-count {
                        font-size: 0.9rem;
                        color: $gray-600;
                    }
                }
            }
        }
    }
    .rate-user-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;        
        li {
            background-color: $white;
            border: 1px solid $gray-400;
            border-radius: 0.4rem;
            padding: 1.2rem;
            > div {
                display: flex;
                justify-content: space-between;
                .rate-user-data {
                    display: flex;
                    gap: 0.5rem;
                    .rate-user-box {
                        width: 2.3rem;
                        height: 2.3rem;
                        border-radius: 50%;
                        background-color: $primary;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $white;
                        font-weight: 400;
                    }
                    .rate-user-name {
                        h5 {
                            font-size: 1.1rem;
                            margin-bottom: 0rem;
                            color: $primary;
                            line-height: 1.3;
                            max-width: 9rem;                                
                            white-space: pre;
                            overflow: hidden;
                            text-overflow: ellipsis;                                
                            display: block;  
                        }                        
                        p {
                            line-height: normal;
                            color: $gray-700;
                        }
                    }
                }
                .rate-user-time {
                    .rating-star {
                        display: flex;
                        gap: 0.4rem;
                        justify-content: flex-end;
                        svg {
                            width: 1rem;
                            height: 1rem;                            
                        }
                        &.primary {
                            color: $primary;
                        }
                        &.success {
                            color: $success;
                        }
                        &.info {
                            color: $info;
                        }
                        &.warning {
                            color: $warning;
                        }
                        &.danger {
                            color: $danger;
                        }
                    }
                    > span {
                        display: flex;
                        gap: 0.3rem;
                        margin-bottom: 0.5rem;
                        color: $gray-500;
                    }
                }
            }
            .message-box {                                                                        
                font-size: 0.86rem;
                color: $gray-600;
                font-weight: 400;
                line-height: 1.4;    
                text-transform: capitalize;   
                border-top: 1px solid $gray-300;
                padding-top: 0.5rem;
                margin-top: 0.3rem;
            }
        }
    }    
}


// theme switch

.theme-box {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    label {
        margin-bottom: 0;
        font-size: 1rem;
    }
    .form-check {
        margin-bottom: 0;
        padding: 0.3rem 0.4rem;
        border: 2px dashed lighten($primary, 14%);
        background-color: $white;
        border-radius: 0.3rem;
        display: flex;
        align-items: center;
        gap: 0.3rem;
        position: relative;      
        overflow: hidden;  
        cursor: pointer;
        input[type='radio'] {
            accent-color: $primary;            
            // position: absolute;
            // top: -23%;
            // right: -14%;
            width: 1rem;
            height: 0.9rem;            
        }
        &:hover {
            border-color: $primary;
        }
    }
    .form-switch {
        margin-bottom: 0;
        padding: 0.2rem 0.4rem;
        border: 2px dashed lighten($primary, 10%);
        border-radius: 0.3rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        position: relative;
        // &::before {
        //     content: "";
        //     position: absolute;            
        //     top: -2%;
        //     width: 100%;
        //     left: 0;
        //     right: 0;
        //     border-top: 3px solid;
        //     border-color: #417df8;            
        //    }
    }    
    .form-check-input[type=checkbox] {   
        margin-left: 0 !important;     
        box-shadow: none;     
        // &:checked + .form-switch{
        //     &::before {
        //      content: "";
        //      position: absolute;            
        //      top: 0;
        //      width: 100%;
        //      border-top: 2px solid;
        //      border-color: #417df8;            
        //     }
        //  }           
    }
}


// subscription purchace deisgn
// .subscription-main {
//     &.container {
//         scroll-padding: 5rem 0;
//     }
// }
.subscription-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100vh;    
}
.subscription-main-plans {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    width: 100%;
    // height: 100vh;    
    .subscription-plans-title {
        text-align: center;
        margin-bottom: 2rem;
        h3 {
            font-size: 3rem;            
            color: $primary;
            margin-bottom: 0.5rem;
        }
        p {
            font-size: 1.2rem;
            color: $gray-600;
        }
    }
    .subscription-main-plans-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2rem;
        .card {
            border: none;
            padding: 1.5rem;            
            border-radius: 1rem;
            position: relative;
            overflow: hidden;
            &:first-child {
                background-color: #FE719B;
            }
            &:nth-child(2) {
                background-color: #7E90FF;
            }
            &:last-of-type {
                background-color: #28A5C0;               
            }
            > * {
                z-index: 1;
                position: relative;
            }
            img {
                max-width: 9rem;
                margin: 0 auto;
                margin-bottom: 1.5rem;
                filter: drop-shadow(0rem 0rem 1.35rem white);
            }
            h5 {
                font-size: 1.8rem;
                margin-bottom: 0rem;
                color: #ffffff;
            }
            h3 {
                font-size: 3.7rem;
                color: #ffffff;
                letter-spacing: 0.2rem;
            }
            ul {
                text-align: left;
                margin: 1rem 0;
                li {
                    margin-bottom: 1.1rem;
                    display: flex;
                    gap: 0.7rem;
                    align-items: center;
                    color: #fff;
                    font-size: 1.2rem;
                    font-weight: 400;
                    svg {
                        width: 1.4rem;
                        height: 1.4rem                   
                    }
                }
            }
            &::before {
                content: "";
                width: 14rem;
                height: 14rem;
                border-radius: 50%;
                background-color: rgba(255, 255, 255, 0.2);
                position: absolute;
                bottom: 0%;
                right: -22%;
                z-index: 0;
            }
            &::after {
                content: "";
                width: 14rem;
                height: 14rem;
                border-radius: 50%;
                background-color: rgba(255, 255, 255, 0.2);
                position: absolute;
                bottom: -26%;
                right: -5%;
                z-index: 0;
            }
        }
    }
    .btn {
        width: auto;
        margin: 0 auto;
        margin-top: 1rem;    
        padding: 0.5rem 3rem;
        font-size: 1.3rem;
    }
}
.api_form{   
    margin-top: 1rem;    
    .row {
        padding: 0.9rem 0.2rem;             
        border-radius: 0.5rem;
        margin: 0 -0.1rem;
    }
    .api_form_top{
        background-color: lighten($secondary, 35%);                 
    }
    .api_form_db{
        background-color: lighten($yellow, 34%);    
        margin-top: 0.7rem !important;        
    }
    .api_form_mail {
        background-color: lighten($success, 42%);       
        margin-top: 0.7rem !important;       
    }
    .api_form_bottom {
        background-color: lighten($pink, 43%);          
        margin-top: 0.7rem !important;         
    }    
    // background-color: lighten($primary, 33%);
    
}

label.radio-card {
    display: block;
    position: relative;
    cursor: pointer;
    &:first-child {
        .card {
            background-color: #FE719B !important;
        }
    }
    &:nth-child(2) {
        .card {
            background-color: #7E90FF !important;
        }
    }
    &:last-of-type {
        .card {
            background-color: #28A5C0 !important;               
        }
    }
    .form-check-input{
        position: absolute;
        appearance: none;
        -webkit-appearance: none;            
        z-index: -1;
    }
    .check-icon {
        width: 1.3rem;
        height: 1.3rem;
        display: inline-block;
        border: solid 2px $white;
        border-radius: 50%;
        transition: 200ms linear;
        position: absolute !important;
        top: 4%;
        right: 3%;
        &:before {
            content: '';
            position: absolute;
            inset: 0;
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: 10px;
            background-position: center center;
            transform: scale(1.6);
            transition: 200ms linear;
            opacity: 0;
        }
    }
    .card{                
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        min-height: auto;
        gap: 0.5rem;
        padding: 0.5rem;                        
    }
    input[type='radio'] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        position: absolute;
        margin-left: 1rem;
        margin-top: 1rem;
        &:checked {
            + .card {
                box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 1px $white;

                .check-icon {
                    background: rgba($primary, 0.5);
                    border-color: $white;
                    transform: scale(1.2);
                    &:before {
                        transform: scale(1.1);
                        opacity: 1;
                    }
                }
            }
        }        
    }
}


@media screen and (max-width: 1919px) {
    .coming-soon-page {
        .img-box {
            img {
                &:first-child {
                    width: 6.6rem;
                }

                &:nth-child(2) {
                    width: 140%;
                }
            }
        }
    }
    label {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 1599px) {
    .coupons-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .coupon-con {
            h5 {
                font-size: 1.3rem !important;
            }
        }
    }

    .coming-soon-page {
        .img-box {
            img {
                width: 10rem;

                &:first-child {
                    width: 6rem;
                }

                &:nth-child(2) {
                    width: 14.5rem;
                }
            }
        }
    }

    .error-page {
        h1 {
            font-size: 6.5rem !important;

            span {
                font-size: 1.9rem !important;
            }
        }

        p {
            font-size: 1.3rem;
        }
    }

    .master-card-list {
        .switch-check {
            height: 1.5rem !important;
        }
    }

    .switch {
        .switch-check {
            width: 6.4rem !important;
            height: 1.5rem !important;
        }

        .switch-btn {
            height: 1.27rem !important;
            top: 0.1rem !important;
            width: 3.35rem !important;
            left: 2.8rem !important;
        }

        input:checked+.switch-check .switch-btn {
            left: 0.1rem !important;
            width: 2.8rem !important;
        }

        .deactive-switch {
            margin-left: 3.075rem !important;
        }

        .active-switch {
            margin-left: 0.413rem !important;
        }
    }

    .support-tickets-list {
        li {
            padding: 0.7rem !important;

            >div {
                .user-box {
                    width: 2.3rem;
                    height: 2.3rem;
                    font-size: 1.1rem;
                }
            }
        }
    }

    .Default_notifications {
        .card-body {
            ul {
                height: calc(100vh - 32rem) !important;
            }
        }
    }

    .subscription-plans-box {     
        grid-gap: 0.8rem;
        grid-template-columns: repeat(3, 1fr);
        .add-subscription-box {
            svg {
                width: 2.9rem;
                height: 2.9rem;
            }
            h3 {
                font-size: 1.3rem !important;
            }
        }            
    }
    .subscription-items-box {
        grid-gap: 0.8rem;
        grid-template-columns: repeat(4, 1fr);
        .add-subscription-box {
            svg {
                width: 2.9rem;
                height: 2.9rem;
            }
            h3 {
                font-size: 1.3rem !important;
            }
        }   
    }

    .rate-data {
        .rate-total-head {
            > li {
                span {
                    font-size: 2.1rem;
                }
            }
        }
        .rate-user-list {
            margin: 0 0.9rem;
            li {
                padding: 0.9rem;
                > div {
                    .rate-user-time {
                        .rating-star {
                            svg {
                                width: 0.8rem;
                                height: 0.9rem;
                            }
                        }
                        > span {
                            gap: 0.2rem;
                            p {
                                font-size: 0.8rem;
                            }
                        }
                    }
                    .rate-user-data {
                        .rate-user-box {
                            width: 2.1rem;
                            height: 2.1rem;
                        }
                        .rate-user-name {                            
                            p {
                                max-width: 9rem;                                
                                white-space: pre;
                                overflow: hidden;
                                text-overflow: ellipsis;                                
                                display: block;
                            }
                        }
                    }
                }
            }
        }        
    }

    .subscription-main-plans {
        .subscription-main-plans-list {
            .card {
                img {
                    max-width: 6rem;                    
                }
                h3 {
                    font-size: 2.9rem;
                }
                &::before {
                    width: 13rem;
                    height: 13rem;
                }
                &::after {
                    width: 13rem;
                    height: 13rem;
                }
            } 
        }        
    }
    .edit_template {
        .jodit-react-container {
            .jodit-wysiwyg {
                padding: 0 !important;
                > div {
                    transform: scale(0.9) translate(-7px, -20px) !important;                     
                }
            }
        }
    }    
}



@media screen and (max-width: 1439px) {
    .switch {
        .switch-btn {
            height: 1.22rem !important;
        }
    }

    .support-tickets-list {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .Default_notifications {
        .card-body {
            ul {
                height: calc(100vh - 30rem) !important;
            }
        }
    }

    .subscription-main-plans {
        .subscription-main-plans-list {
            .card {
                padding: 1.2rem 2rem;
            }
        }
    }
    .edit_template {
        .jodit-react-container {
            .jodit-wysiwyg {
                > div {
                    transform: scale(0.7) translate(-73px, -83px) !important;
                }
            }
        }
    }    
}
@media screen and (max-width: 1365px) {
    .rate-data {        
        .rate-user-data {            
            .rate-user-name {
                h5 {                    
                    max-width: 7rem !important;                    
                }
                p {
                    max-width: 7rem !important;
                }
            }
        }      
    }

    .subscription-main {
        &.container {
            max-width: calc(100% - 6rem) !important;
            margin: 0 3rem;
        }        
      }
}
@media screen and (max-width: 1279px) {
    .subscription-plans-box {     
        grid-template-columns: repeat(2, 1fr);
        .add-subscription-box {
            svg {
                width: 2.4rem;
                height: 2.4rem;
            }           
        }           
    }
    .subscription-items-box {     
        grid-template-columns: repeat(3, 1fr);
        .add-subscription-box {
            svg {
                width: 2.4rem;
                height: 2.4rem;
            }           
        }           
    }
    .rate-data {
        .rate-user-list {
            margin: 0;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .subscription-main-plans {
        .subscription-plans-title {
            h3 {
                font-size: 2.4rem;
            }
        }        
        .subscription-main-plans-list {         
            grid-gap: 1.3rem;
            .card {
                padding: 1.1rem 1.1rem;
                h5 {
                    font-size: 1.5rem;
                }
                h3 {
                    font-size: 2.3rem;
                }
                &::before {
                    width: 12rem;
                    height: 12rem;
                    right: -38%;
                }
                &::after {
                    width: 12rem;
                    height: 12rem;
                    bottom: -28%;
                }
            }
        }
    }
}
@media screen and (max-width: 1023px) {
    .subscription-main {
        &.container {
            max-width: calc(100% - 3rem) !important;
            margin: 0 1.5rem;
        }        
    }
    .subscription-main-plans {
        display: block;             
        height: 100%;
        padding: 4% 0;
        .subscription-main-plans-list {
            grid-gap: 0.9rem;
            // grid-template-columns: repeat(3, 1fr);
            .card {
                img {
                    max-width: 4.8rem;
                }
                li {
                    margin-bottom: 0.6rem !important;                    
                    font-size: 1rem !important;
                    gap: 0.5rem !important;
                    svg {
                        width: 1.2rem !important;
                        height: 1.1rem !important;
                    }
                }
                &::before {
                    width: 11rem;
                    height: 11rem;                    
                }
                &::after {
                    width: 11rem;
                    height: 11rem;                    
                }
            }
        }
    }
    .btn-light {     
        font-size: 0.9rem;
    }
}
@media screen and (max-width: 767px) {
    .subscription-main-plans {       
        .subscription-main-plans-list {          
            .card {
                padding: 0.9rem 0.8rem;
                border-radius: 0.7rem;
                h3 {
                    font-size: 2rem;
                    letter-spacing: 0.1rem;
                }
                img {
                    max-width: 3.7rem;
                    margin-bottom: 0.8rem;
                }                
            }
        }
    }
}
@media screen and (max-width: 639px) {
    .subscription-main-plans {       
        .subscription-main-plans-list {          
            grid-template-columns: repeat(1, 1fr);   
            img {
                max-width: 4.5rem !important;
            }        
            .card {
                &::before {
                    width: 12rem;
                    height: 12rem;
                    right: -15%;
                    bottom: -2.2%;
                }
                &::after {
                    width: 12rem;
                    height: 12rem;
                    right: -1%;
                    bottom: -24%;
                }
            }
        }
        .subscription-plans-title {
            margin-bottom: 1.2rem;
            h3 {
                font-size: 2rem;
            }
            p {
                font-size: 1.1rem;
            }
        }
    }
}

@media screen and (max-width: 479px) {
    .subscription-main {
        &.container {
            max-width: calc(100% - 2rem) !important;
            margin: 0 1rem;
        }        
    }
    .subscription-plans-title {       
        p {
            font-size: 1rem;
        }
    }
}